<template>
  <v-app theme="light">
    <v-main style="height: 100vh;">
      <router-view />
    </v-main>
  </v-app>

</template>

<script>
export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>